<template>
  <el-dialog  :visible.sync="showModel" :close-on-click-modal="false">
    <template slot="title">
        <div class="dialogTitles">
          <span></span> <b>地图定位</b>
        </div>
      </template>
    <div id="mapAdd" style="width: 100%; height: 500px"></div>
  </el-dialog>
</template>

<script>
import { forEach } from "jszip";
// import {getTrackWorkPage} from "@/RequestPort/checkingIn";
export default {
  name: "workLocus",
  data() {
    return {
      showModel: false,
      // 打卡点
      labelsLayer: null,
    };
  },
  mounted() {
    // (type='line' and id in (1)) or (type='point' and id in (1))
  },
  methods: {
    open(val) {
      let that = this;
      this.showModel = true;
      this.$nextTick(() => {
        let ponit = []
        if(val){
          ponit = val
        }else{
          ponit = [116.406315, 39.908775]
        }
        // if(!that.map){
        that.map = new AMap.Map("mapAdd", {
          resizeEnable: true,
          center: ponit,
          zoom: 17,
        });
        let markerZhong = new AMap.Marker({
          // position: new AMap.LngLat(
          //   val.path[i][j][lastLineLength - 1][0],
          //   val.path[i][j][lastLineLength - 1][1]
          // ),
          // position: new AMap.LngLat(116.38,39.92),
          position: ponit,
          offset: new AMap.Pixel(-13, -30),
          zoom: 13,
        });
        that.map.add(markerZhong);

      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding: 20px;
}
</style>