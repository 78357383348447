<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="60%" @close="closeDialog">
      <template slot="title">
        <div class="dialogTitles">
          <span></span> <b>历史数据</b>
        </div>
      </template>
      <div>
        <el-table height="500" border :data="itemList" style="width: 100%;">
          <el-table-column prop="equipmentName" label="设备名称"> </el-table-column>
          <el-table-column prop="description" label="所在位置"> </el-table-column>
          <el-table-column prop="monitorPointName" label="监测点位"> </el-table-column>
          <el-table-column prop="val" label="远传数据"> </el-table-column>
          <el-table-column prop="dataType" label="数据类型"> </el-table-column>
          <el-table-column prop="warningDescribe" label="异常信息"> </el-table-column>
          <el-table-column prop="uploadTime" label="上报时间"> </el-table-column>
          <el-table-column prop="collectTime" label="采集时间">
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination :current-page="pageParams.current" :background="true" :page-sizes="[ 30, 50, 100]"
            :page-size="pageParams.size" layout="total, sizes, prev, pager, next, jumper" :total="total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { historyPage } from '@/apis/warn/alarmManagement/realData'
export default {
  data() {
    return {
      pageParams: {
        size: 100,
        current: 1,
        equipmentId: '',
      },
      time: '',
      total: 0,
      itemList: [],
      dialogVisible: false,
    }
  },
  methods: {
    search() {
      this.pageParams.equipmentId = ''
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    closeDialog() {
      this.pageParams.equipmentId = ''
      this.pageParams.size = 100
      this.pageParams.current = 1
    },
    openData(data) {
      this.pageParams.equipmentId = data
      this.dialogVisible = true
      this.loadList(this.pageParams)
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.loadList(this.pageParams)
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.loadList(this.pageParams)
    },
    loadList(obj) {
      historyPage(obj).then(res => {
        if (res.code == 200) {
          this.itemList = res.data.list
          this.total = res.data.total
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}

.devInfo {
  background: #eaeaea;
  padding: 10px;
  margin: 10px 0;
}
</style>
