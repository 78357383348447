
import axios from '@/common/js/request'
export function getRealTime(params) {
  return axios({
    url: '/scada/realTime/realTime',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params
  })
}

export function historyPage(params) {
  return axios({
    url: '/scada/history/page',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params
  })
}



// //管线-删除
// export function lineDelete(params) {
//   return axios({
//     url: '/zypatrol/line/delete',
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data:params
//   })
// }




