<template>
  <div class="mainBody">
    <div class="realData">
      <div class="topSearch" ref="other">
        <div class="tabsBox">
          <div class="tabs" @click="handleClick(2)">
            <div class="tab" :class="{ 'actie': pageParams.alarmInstallType == 2 }">管网</div>
          </div>
          <div class="tabs" @click="handleClick(1)">
            <div class="tab" :class="{ 'actie': pageParams.alarmInstallType == 1 }">场站</div>
          </div>
        </div>
        <div class="search">
          <el-form :inline="true" :model="pageParams" class="demo-form-inline" label-position="right">
            <el-form-item label="编码">
              <el-input v-model="pageParams.code" clearable class="search-input" placeholder="报警器编码"></el-input>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="pageParams.equipmentName" clearable class="search-input" placeholder="报警器名称"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="pageParams.generalStatus" class="search-input" clearable filterable placeholder="请选择">
                <!-- <el-option label="启用" :value="1"></el-option>
                <el-option label="禁用" :value="0"></el-option> -->
                <el-option v-for="item in equipmentStatus" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
              </el-select>
              
            </el-form-item>
            <el-form-item>
              <div class="search-q">
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="info" plain @click="reset">重置</el-button>
              </div>
            </el-form-item>
          </el-form>

        </div>
      </div>
      <div class="content" :style="{ height: height }">
        <div class="listBox">
          <div v-for="(item, index) in list" :key="index" class="contentB"
            :style="item.errorInfo == '无异常' ? '' : { borderColor: '#F45A5C' }">
            <div class="headerB" :style="item.errorInfo == '无异常' ? '' : { backgroundColor: '#F45A5C' }">
              <div class="headName textOver" :title="item.equipmentName">{{ item.equipmentName }}</div>
              <!-- 暂无信号强度字段 -->
              <!-- <div class="headSign">
                <img src="../../../assets/signal.png" alt="">
                {{ item.signalStrength }}
              </div> -->
            </div>
            <div class="contentBox">
              <el-descriptions :column="1" :label-style="{ color: '#727E97' }" :content-style="{ color: '#26334E' }">
                <el-descriptions-item label="所在位置">
                  <span>{{ item.location ? item.location : '--' }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="设备型号">
                  <span>{{ item.equipmentModel ? item.equipmentModel : '--' }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="设备规格">
                  <span>{{ item.equipmentSpecs ? item.equipmentSpecs : '--' }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="异      常"
                  :content-style="item.errorInfo == '无异常' ? '' : { color: '#F45A5C' }">
                  {{ item.errorInfo ? item.errorInfo : '--' }}
                </el-descriptions-item>
                <el-descriptions-item v-for="(son, indexs) in item.monitorPointList" :key="indexs"
                  :label="son.monitorPointName">
                  <span :style="son.warningFlag == 0 ? { color: '#26334E' } : { color: '#F45A5C' }">
                    {{ son.monitorPointValue }}{{ son.monitorPointUnit }} </span>&nbsp;&nbsp;
                  <span
                    :style="son.warningFlag == 0 ? { fontSize: '12px', color: '#727E97' } : { fontSize: '12px', color: '#F45A5C' }">
                    ( {{ son.uploadTime }} ) </span>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <div class="btnBox">
              <div class="btn">
                <div @click="onHistory(item)">
                  <img style="width: 15px;height: 15px;margin-right: 5px;top: 2px;position: relative;"
                    src="../../../assets/history.png" />
                  历史数据
                </div>
              </div>
              <div class="shu"></div>
              <div class="btn">
                <div @click="showDetil(item)">
                  <img style="width: 15px;height: 15px;margin-right: 5px;top: 2px;position: relative;"
                    src="../../../assets/positioning.png" />
                  地图定位
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination background layout="total,sizes,prev, pager, next" :page-size="pageParams.size"
          :current-page="pageParams.current" :page-sizes="[30, 50, 100]" :total="total"
          @size-change="handleSizeChange" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
    <DataHistory ref="DataHistory"></DataHistory>
    <map-locus ref="mapLocus"></map-locus>
  </div>
</template>

<script>
import DataHistory from './components/dataHistory'
import mapLocus from "./components/mapLocus";
import { mapActions, mapState } from 'vuex'
import { getRealTime } from '@/apis/warn/alarmManagement/realData'
export default {
  name: 'realData',
  components: {
    DataHistory,
    mapLocus
  },
  data() {
    return {
      pageParams: {
        alarmInstallType: 2,
        current: 1,
        size: 100,
        equipmentType: 4
      },
      list: [
      ],
      total: 0,
      height: 0,

    }
  },
   computed: {
      ...mapState('dict', ['equipmentStatus'])
  },
  mounted() {
    this.getDropdown('equipmentStatus')
    this.$nextTick(() => {
      this.height = `calc(100% - ${this.$refs.other.scrollHeight}px)`
    })
    this.loadList(this.pageParams)
  },
  methods: {
    ...mapActions('dict', ['getDropdown']),
    handleClick(e) {
      this.pageParams = {
        alarmInstallType: 2,
        current: 1,
        size: 100,
        equipmentType: 4
      }
      if (this.pageParams.alarmInstallType != e) {
        this.pageParams.alarmInstallType = e
      }
      this.loadList(this.pageParams)
    },
    search() {
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    reset() {
      let alarmInstallType = this.pageParams.alarmInstallType
      this.pageParams = {
        alarmInstallType: alarmInstallType,
        current: 1,
        size: 100,
        equipmentType: 4
      }
      this.loadList(this.pageParams)
    },
    handleSizeChange(val) {
      this.pageParams.current = 1
      this.pageParams.size = val
      this.loadList(this.pageParams)
    },
    currentChange(current) {
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    onHistory(item) {
      this.$refs.DataHistory.openData(item.equipmentId)
    },
    showDetil(e) {
      if (e.point) {
        // 'POINT(118.207792 39.623391)'
        let str = (e.point).slice(6, -1)
        let arr = str.split(' ')
        this.$refs.mapLocus.open(arr)
      } else {
        this.$message.warning('该场站未标点')
      }
    },
    loadList(data) {
      let obj = JSON.parse(JSON.stringify(data))
      getRealTime(obj).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-descriptions__body .el-descriptions__table {
  white-space: nowrap;
}

::v-deep.el-descriptions-item__content {
  color: #010101;
  font-size: 14px;
}

::v-deep.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 4px;

  .el-descriptions-item__container {
    display: block !important;
  }
}

::v-deep .search {
  .el-form-item__content {
    line-height: 43px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .el-form-item__label {
    color: #0F0F0F;
    padding-right: 5px;
    line-height: 43px;
  }

  .el-input__inner {
    height: 28px;
  }

  .el-input__suffix {
    top: -3px
  }

  .el-button {
    padding: 6px 10px;
  }
}

.mainBody {
  padding: 10px;
  height: calc(100vh - 175px);

  .realData {
    background: #fff;
    height: 100%;
    position: relative;

    .search-input {
      width: 150px;
    }

    .topSearch {
      border-bottom: 1px solid #E3E3E3;
      display: flex;
      justify-content: space-between;

      .tabsBox {
        display: flex;
        // justify-content: space-between;
        flex: 0 0 150px;

        .tabs {
          cursor: pointer;
          padding: 0 20px;

          .tab {
            height: 100%;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;
            display: flex;
            align-items: center;

            &.actie {
              color: #1082FF;
              border-bottom: 2px solid #1082FF;
            }
          }
        }

      }

      .search {}
    }

    .content {
      width: 100%;
      // height: calc(100% - 45px);
      padding: 20px;
      padding-right: 0;
      box-sizing: border-box;
      background: #fff;

      .listBox {
        height: calc(100% - 40px);
        overflow: auto;

        .contentB:nth-of-type(5n) {
          margin-right: 0;
        }

        .contentB {
          height: 230px;
          width: 18.6%;
          float: left;
          position: relative;
          border: 1px solid #57C290;
          margin-right: 16px;
          margin-bottom: 16px;
          border-radius: 5px;

          .btnBox {
            height: 38px;
            background: #F6F6F6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .shu {
              width: 1px;
              height: 20px;
              background: #E3E3E3;
            }

            .btn {
              flex: 1;
              display: flex;
              justify-content: center;
              color: #1082FF;

              div {
                cursor: pointer;
              }
            }
          }

          .headerB {
            height: 44px;
            background-color: #57C290;
            line-height: 44px;
            color: #ffffff;
            padding: 0 16px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;

            .headName {
              width: calc(100% - 50px);
              font-weight: bold;
            }

            .headSign {
              display: flex;
              align-items: center;
              font-size: 12px;

              img {
                margin-right: 6px;
              }
            }
          }

          .contentBox {
            padding: 12px 16px;
            position: relative;
            height: calc(100% - 110px);
            overflow: auto;



            .continer1 {
              font-size: 15px;
              font-weight: 600;
              color: #010101;
              width: calc(100% - 25px);
              height: 25px;
            }

            .singleWifi {
              width: 25px;
              height: 40px;
              position: absolute;
              right: 20px;
              top: 20px;
              text-align: center;
              color: #06bb13;
              font-size: 12px;
              font-weight: 600;
            }

            .continer2 {
              font-size: 14px;
              font-weight: 400;
              color: #7f7f7f;
              margin-top: 6px;
              padding-bottom: 15px;
              border-bottom: 1px solid #dadada;
            }
          }

          .containFooter {
            height: 42px;
            background: #F6F6F6;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-radius: 5px;
            display: flex;
            color: #1082ff;

            .Footer {
              flex: 1;
              line-height: 42px;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>